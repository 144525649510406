import React from 'react';

import { CModal } from '@coreui/react';
import tw, { styled } from 'twin.macro';

import { ReactComponent as CloseIcon } from '../../../assets/icons/close.svg';
import { ReactComponent as WarningIcon } from '../../../assets/icons/warning.svg';
import { Text } from '../../../containers/MesssageContainers';
import { FlexCentredRow, FlexRowEnd, FlexRowSpaceBetween } from '../../../containers/ScreenContainers';
import { Button, LoadingButton } from '../../buttons';

const StyledModal = styled(CModal)`
    .modal-content {
        border: none;
        border-radius: 8px;
        padding: 2.4rem;
        width: 59.2rem;
    }
`;
const Input = tw.input`
    border-[1px]
    border-[#E5E7EB]
    pl-3
    rounded
    outline-none
    focus:ring-[#6366F1]
    focus:ring-2
    placeholder:text-[#6B7280]
    placeholder:text-[1.4rem]
    leading-6
    font-normal
    text-[1.4rem]
    w-full
    h-[4rem]
`;

const NewConfirmActionDialogue = ({
    children,
    show,
    close,
    svgIcon,
    title,
    subtitle,
    bodyText,
    confirmAction,
    btn2Text,
    input,
    placeholder,
    borderLine,
    bgColor,
    value,
    onChange,
    isLoading,
    withIcon = true,
    noStyle,
    type,
    withClose = true,
    closeOnBackdrop = true,
    withCancelBtn = true,
}) => {
    return (
        <StyledModal
            show={show}
            onClose={close}
            centered
            closeOnBackdrop={closeOnBackdrop}
            backdrop={true}
            color="info"
        >
            <FlexRowSpaceBetween>
                <FlexCentredRow>
                    {withIcon ? svgIcon ? svgIcon : <WarningIcon className="mr-[1.215rem]" /> : null}
                    <Text as="h3" size="1.9rem" weight="600">
                        {title}
                    </Text>
                </FlexCentredRow>
                {withClose && <CloseIcon onClick={close} className="cursor-pointer" />}
            </FlexRowSpaceBetween>
            <Text align="left" size="1.6rem" bottom="1.6rem" color="#374151" top="1.6rem">
                {subtitle}
            </Text>
            {borderLine && <hr className="my-[1.5rem]" />}
            {bodyText && (
                <Text
                    align="left"
                    size="1.6rem"
                    color="#374151"
                    top="1.6rem"
                    bottom="3.2rem"
                    fontStyle={noStyle ? '' : 'italic'}
                >
                    {bodyText}
                </Text>
            )}
            {children}
            {input && <Input type={type || 'text'} placeholder={placeholder} value={value} onChange={onChange} />}
            <FlexRowEnd className="gap-x-[16px] mt-[3rem]">
                {withCancelBtn && (
                    <Button onClick={close} border="0.5px solid #D1D5DB">
                        Cancel
                    </Button>
                )}
                <LoadingButton loading={isLoading} onClick={confirmAction} bgColor={bgColor || '#EF4444'} color="#fff">
                    {btn2Text || 'Confirm'}
                </LoadingButton>
            </FlexRowEnd>
        </StyledModal>
    );
};

export default NewConfirmActionDialogue;
