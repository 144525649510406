import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { PageTitle } from '../../containers/MesssageContainers';
import { FlexDiv } from '../../containers/ScreenContainers';
import usePersistedState from '../../hooks/usePersistedState';
import PageLayout from '../../layout/page-layout';
import { getHandbook } from '../../redux/handbook/action';
import { localStoreKeys } from '../../utils/localStore';

import HandbookMobile from './handbookMobile';

const fields = [
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Name',
        accessorKey: 'name',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'Description',
        accessorKey: 'description',
    },
    {
        cell: (props) => <p>{new Date(props?.getValue()).toLocaleDateString()}</p>,
        header: 'Updated At',
        accessorKey: 'updatedAt',
    },
    {
        cell: (props) => <p>{props?.getValue()}</p>,
        header: 'File Size',
        accessorKey: 'size',
    },
];

const Handbook = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { isLoading, handbooks } = useSelector((state) => state.handbook);
    const { sessions, activeSession } = useSelector((state) => state.session);

    const [searchValue, setSearchValue] = useState('');
    const [openDropdown, setOpenDropdown] = useState('');
    const [selectedItem, setSelectedItem] = useState(null);
    const [session, setSession] = usePersistedState(activeSession || '', localStoreKeys.SelectedSession);

    const responsive = window.innerWidth <= 769;
    const [isMobile, setIsMobile] = useState(responsive);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sessionItems = sessions?.map((item) => ({
        name: item.admissionYear,
        click: () => {
            setSession(item.admissionYear);
            setOpenDropdown(null);
        },
    }));

    const handleOpenDropdown = (dropdown) => {
        if (openDropdown === dropdown) {
            setOpenDropdown(null);
        } else {
            setOpenDropdown(dropdown);
        }
    };

    const handleSelect = (item) => {
        const selected = selectedItem?._id === item._id;
        if (selected) {
            setSelectedItem(null);
        } else {
            setSelectedItem(item);
        }
    };

    const modifiedHandbook = handbooks?.map((item) => {
        return {
            name: (
                <td
                    style={{ color: '#2563EB', cursor: 'pointer' }}
                    onClick={() => navigate(`${item?._id}?session=${session}`)}
                >
                    {item?.title}
                </td>
            ),
            size: <td>{item.handbookUpload?.[0].size}</td>,
            ...item,
        };
    });

    useEffect(() => {
        if (session && session !== '') {
            dispatch(getHandbook(session));
        }
    }, [dispatch, session]);

    return (
        <>
            <FlexDiv className="mb-2 md:mb-6">
                <PageTitle>Handbook</PageTitle>
            </FlexDiv>
            {isMobile ? (
                <HandbookMobile searchValue={searchValue} setSearchValue={setSearchValue} data={handbooks} />
            ) : (
                <PageLayout
                    noMargin
                    showTableUtils
                    showFilter
                    sessionItems={sessionItems}
                    searchable={false}
                    sessionValue={session}
                    onSessionFilterClick={() => handleOpenDropdown('sessions')}
                    openSessionFilter={openDropdown === 'sessions'}
                    closeSessionFilter={() => setOpenDropdown(null)}
                    data={modifiedHandbook || []}
                    fields={fields}
                    onRowClick={(item) => handleSelect(item)}
                    loading={isLoading}
                />
            )}
        </>
    );
};

export default Handbook;
