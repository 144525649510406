import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { LOADING, STOP_LOADING, GET_LECTURE_TIMETABLE, GET_EXAM_TIMETABLE, GET_ACADEMIC_CALENDAR } from './slice';

export const getLectureTimetable = (session, semester) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/timetable?session=${session}&semester=${semester}`);
        if (response?.status === 200) {
            dispatch(GET_LECTURE_TIMETABLE(response.data.data));
            return response.data.data;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getExamTimetable = (session) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/exam-timetable-session?session=${session}`);
        if (response.status === 200) {
            dispatch(GET_EXAM_TIMETABLE(response.data.data));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getAcadCalendar = () => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/school-calendar`);
        if (response.status === 200) {
            dispatch(GET_ACADEMIC_CALENDAR(response.data.data));
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
