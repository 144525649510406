import axios from 'axios';
import { toast } from 'react-toastify';

import { parseJSON } from '../utils/useJSON';

const ERROR_MESSAGES = {
    401: 'Session expired!',
    500: 'Internal server error',
};

const showErrorToast = (status, defaultMessage, customMessage) => {
    const toastId = `${status}-error-toast-Id`;
    toast.error(customMessage || defaultMessage, { toastId });
};

let triggerSessionTimeoutModalCallback = () => {};

export const setUnauthorizedHandler = (callback) => {
    triggerSessionTimeoutModalCallback = callback;
};

const defaultOptions = {
    baseURL: process.env.REACT_APP_API_BASE_URL,
    headers: {
        'Access-Control-Allow-Origin': '*',
    },
};

const axiosInstance = axios.create(defaultOptions);

axiosInstance.interceptors.request.use(
    (config) => {
        if (config && config.headers) {
            const token = localStorage.getItem('token');
            config.headers.token = token;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    async (error) => {
        if (!error.response) return Promise.reject(error); // No response means a network error

        const { status, data } = error.response;
        const token = parseJSON('token', '');

        if (status === 401) {
            if (token) {
                // Only show modal if user is within the app
                triggerSessionTimeoutModalCallback();
            } else {
                const errorMsg = !token ? data.message : ERROR_MESSAGES[401];
                showErrorToast(401, ERROR_MESSAGES[401], errorMsg);
            }
            return Promise.reject(error);
        }

        if (status === 500) {
            showErrorToast(500, ERROR_MESSAGES[500], data.message);
            return Promise.reject(error);
        }
        return Promise.reject(error);
    },
);

export default axiosInstance;

export const createAPIRequest = (config) => axiosInstance(config);
