import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    student: {},
    isLoading: false,
    error: null,
    studentDetails: {},
    acceptanceLetter: {},
    academyData: {
        primaryInfo: [
            {
                primarySchool: '',
                from: '',
                to: '',
                qualificationObtain: '',
            },
        ],
        secondaryInfo: [
            {
                secondarySchool: '',
                from: '',
                to: '',
                qualificationObtain: '',
            },
        ],
        tertiaryInfo: [
            {
                tertiarySchool: '',
                from: '',
                to: '',
                qualificationObtain: '',
            },
        ],
        resultInfo: [
            {
                examType: '',
                examNumber: '',
                examYear: '',
                results: [
                    {
                        subject: '',
                        grade: '',
                    },
                ],
            },
        ],
        utmeInfo: [
            {
                utmeNumber: '',
                utmeYear: '',
                utmeResults: [
                    {
                        subject: '',
                        score: '',
                    },
                ],
            },
        ],
        tertiaryResult: [
            {
                examNumber: '',
                examYear: '',
                tertiaryResults: [
                    {
                        subject: '',
                        score: '',
                    },
                ],
            },
        ],
    },
    firstLevel: '',
    secondLevel: '',
    thirdLevel: '',
};

const studentSlice = createSlice({
    name: 'student',
    initialState,
    reducers: {
        SAVE_STUDENT: (state, action) => {
            state.student = action.payload;
        },
        REMOVE_STUDENT: (state) => {
            state.student = initialState.student;
        },
        REGISTRATION_DETAILS: (state, action) => {
            state.studentDetails = action.payload;
        },
        GET_ACADEMY_DATA: (state, action) => {
            state.academyData = action.payload;
        },
        LOADING: (state) => {
            state.isLoading = true;
        },
        STOP_LOADING: (state) => {
            state.isLoading = false;
        },
        ACCEPTANCE_LETTER: (state, action) => {
            state.acceptanceLetter = action.payload;
        },
        STORE_FIRST_LEVEL: (state, action) => {
            state.firstLevel = action.payload;
        },
        STORE_SECOND_LEVEL: (state, action) => {
            state.secondLevel = action.payload;
        },
        STORE_THIRD_LEVEL: (state, action) => {
            state.thirdLevel = action.payload;
        },
    },
});

export const {
    SAVE_STUDENT,
    REMOVE_STUDENT,
    REGISTRATION_DETAILS,
    ACCEPTANCE_LETTER,
    LOADING,
    STOP_LOADING,
    GET_ACADEMY_DATA,
    STORE_FIRST_LEVEL,
    STORE_SECOND_LEVEL,
    STORE_THIRD_LEVEL,
} = studentSlice.actions;

export default studentSlice.reducer;
