import { useEffect, useState } from 'react';

const usePersistedState = (defaultValue, storageKey) => {
    const [value, setValue] = useState(() => {
        const stickyValue = window.localStorage.getItem(storageKey);

        return stickyValue !== null ? JSON.parse(stickyValue) : defaultValue;
    });

    useEffect(() => {
        window.localStorage.setItem(storageKey, JSON.stringify(value));
    }, [storageKey, value]);

    return [value, setValue];
};

export default usePersistedState;
