import { axiosInstance, currentAPI } from '../../config';
import handleApiError from '../handleApiError';

import { GET_HANDBOOK, GET_HANDBOOK_BY_ID, LOADING, STOP_LOADING } from './slice';

export const getHandbook = (session) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/${session}`);
        dispatch(GET_HANDBOOK(response.data.data));
        return true;
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const getHandbookById = (id, session) => async (dispatch) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.get(`${currentAPI}/${session}/${id}`);
        if (response.status === 200) {
            dispatch(GET_HANDBOOK_BY_ID(response.data.data[0]));
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};
