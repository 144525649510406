import axios from 'axios';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';

import { axiosInstance, currentAPI } from '../../config';
import { capitalizeFirstLetter } from '../../utils';
import handleApiError from '../handleApiError';
import { getActiveSemester, getActiveSession, getAdmissionSessions } from '../session/actions';
import { getAcadCalendar } from '../timetable/actions';
import { STORE_FIRST_LEVEL, STORE_SECOND_LEVEL, STORE_THIRD_LEVEL, SAVE_STUDENT } from '../user/slice';

import { LOGIN, LOADING, STOP_LOADING } from './slice';

const cookies = new Cookies();

export const signup = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.patch(`${currentAPI}/register`, payload);
        if (response.status === 200) {
            toast.success(response.data.message);
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const authLogin = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const response = await axiosInstance.post(`${currentAPI}/login-student`, payload);
        if (response.status === 200) {
            const { user, organizationName, organizationLogo } = response?.data?.data || {};
            const { token } = response?.data || {};
            cookies.set('accessToken', token);
            localStorage.setItem('token', token);
            const structureType = user?.academicStructure?.split('-') || '';
            const {
                _id,
                firstName,
                lastName,
                middleName,
                matricNumber,
                utmeRegNumber,
                studentEmail,
                program,
                level,
                admissionSession,
                admissionType,
                utmeScore,
                postUtmeScore,
                status,
                admissionYear,
                faculty,
                isAcceptanceFee,
            } = user;

            const toBeSavedUserData = {
                firstName,
                lastName,
                middleName,
                matricNumber,
                utmeRegNumber,
                studentEmail,
                program,
                level,
                admissionSession,
                admissionType,
                token,
                utmeScore,
                postUtmeScore,
                status: !isAcceptanceFee ? 'Accepted' : status,
                admissionYear,
                id: _id,
                organizationName,
                organizationLogo,
                faculty,
                isAcceptanceFee,
                ...user,
            };
            dispatch(LOGIN(toBeSavedUserData));
            dispatch(SAVE_STUDENT(toBeSavedUserData));
            dispatch(STORE_FIRST_LEVEL(capitalizeFirstLetter(structureType?.[0])));
            dispatch(STORE_SECOND_LEVEL(capitalizeFirstLetter(structureType?.[1])));
            dispatch(STORE_THIRD_LEVEL(capitalizeFirstLetter(structureType?.[2])));
            dispatch(getAdmissionSessions());
            dispatch(getActiveSession());
            dispatch(getActiveSemester());
            dispatch(getAcadCalendar());
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const forgotPassword = (payload) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const res = await axiosInstance.post(`${currentAPI}/forgot-password`, payload);
        if (res.status === 200) {
            toast.success(res.data.message || 'Success!');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const changePassword = (payload, token) => async (dispatch, getState) => {
    dispatch(LOADING());
    try {
        const res = await axios.post(`${currentAPI}/change-password`, payload, {
            headers: { token },
        });
        if (res.status === 200) {
            toast.success('Success!');
            return true;
        }
    } catch (error) {
        handleApiError(error);
    } finally {
        dispatch(STOP_LOADING());
    }
};

export const logoutUser = (navigate) => async (dispatch) => {
    dispatch({ type: 'USER_LOGOUT' });
    cookies.remove('accessToken');
    localStorage.clear();
    navigate('/login');
};
